var render = function () {
  var _vm$BUTTON_ACTION_PER, _vm$BUTTON_ACTION_PER2, _vm$BUTTON_ACTION_PER3, _vm$invoiceData, _vm$invoiceData2, _vm$BUTTON_ACTION_PER4, _vm$BUTTON_ACTION_PER5, _vm$BUTTON_ACTION_PER6, _vm$invoiceData3, _vm$invoiceData4, _vm$BUTTON_ACTION_PER7, _vm$BUTTON_ACTION_PER8, _vm$BUTTON_ACTION_PER9, _vm$invoiceData5, _vm$invoiceData6, _vm$invoiceData7, _vm$invoiceData$compa, _vm$invoiceData8, _vm$invoiceData8$comp;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_c('BRow', [_c('BCol', {
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column gap-3 p-2"
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "airline",
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.onExportPDFHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.exportPDF')) + " ")]), _c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "airline",
      "disabled": !((_vm$BUTTON_ACTION_PER = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER !== void 0 && (_vm$BUTTON_ACTION_PER2 = _vm$BUTTON_ACTION_PER[(_vm$invoiceData = _vm.invoiceData) === null || _vm$invoiceData === void 0 ? void 0 : _vm$invoiceData.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER2 !== void 0 && (_vm$BUTTON_ACTION_PER3 = _vm$BUTTON_ACTION_PER2[(_vm$invoiceData2 = _vm.invoiceData) === null || _vm$invoiceData2 === void 0 ? void 0 : _vm$invoiceData2.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER3 !== void 0 && _vm$BUTTON_ACTION_PER3.EDIT) || _vm.isLoading || !_vm.companyId
    },
    on: {
      "click": _vm.handleEditInvoice
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.edit')) + " ")]), _c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "airline",
      "disabled": !((_vm$BUTTON_ACTION_PER4 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER4 !== void 0 && (_vm$BUTTON_ACTION_PER5 = _vm$BUTTON_ACTION_PER4[(_vm$invoiceData3 = _vm.invoiceData) === null || _vm$invoiceData3 === void 0 ? void 0 : _vm$invoiceData3.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER5 !== void 0 && (_vm$BUTTON_ACTION_PER6 = _vm$BUTTON_ACTION_PER5[(_vm$invoiceData4 = _vm.invoiceData) === null || _vm$invoiceData4 === void 0 ? void 0 : _vm$invoiceData4.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER6 !== void 0 && _vm$BUTTON_ACTION_PER6.SEND_EMAIL) || _vm.isLoading || !_vm.companyId
    },
    on: {
      "click": _vm.onSendEmailHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sendEmail')) + " ")]), _c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "airline",
      "disabled": !((_vm$BUTTON_ACTION_PER7 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER7 !== void 0 && (_vm$BUTTON_ACTION_PER8 = _vm$BUTTON_ACTION_PER7[(_vm$invoiceData5 = _vm.invoiceData) === null || _vm$invoiceData5 === void 0 ? void 0 : _vm$invoiceData5.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER8 !== void 0 && (_vm$BUTTON_ACTION_PER9 = _vm$BUTTON_ACTION_PER8[(_vm$invoiceData6 = _vm.invoiceData) === null || _vm$invoiceData6 === void 0 ? void 0 : _vm$invoiceData6.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER9 !== void 0 && _vm$BUTTON_ACTION_PER9.SIGN || ((_vm$invoiceData7 = _vm.invoiceData) === null || _vm$invoiceData7 === void 0 ? void 0 : _vm$invoiceData7.signStatus) === 'UNSIGNED') || _vm.isLoading || !_vm.companyId
    },
    on: {
      "click": _vm.onSignInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.signAndReleaseInvoice')) + " ")]), _c('BButton', {
    attrs: {
      "pill": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.handleExit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.exit')) + " ")])], 1)]), _c('BCol', {
    staticStyle: {
      "overflow": "auto",
      "max-height": "100vh"
    },
    attrs: {
      "cols": "10"
    }
  }, [_c('div', {
    staticClass: "p-1 border-1 border-dark"
  }, [_vm.pdfData ? _c('VuePdfEmbed', {
    ref: "pdfFileRef",
    attrs: {
      "source": _vm.pdfData
    }
  }) : _vm._e()], 1)])], 1), _c('InvoiceSignModal', {
    attrs: {
      "invoice-data": _vm.invoiceData,
      "companyId": (_vm$invoiceData$compa = (_vm$invoiceData8 = _vm.invoiceData) === null || _vm$invoiceData8 === void 0 ? void 0 : (_vm$invoiceData8$comp = _vm$invoiceData8.company) === null || _vm$invoiceData8$comp === void 0 ? void 0 : _vm$invoiceData8$comp.id) !== null && _vm$invoiceData$compa !== void 0 ? _vm$invoiceData$compa : null
    },
    on: {
      "refetch": _vm.fetchInvoicePdf
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }